<script setup lang="ts">
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import isBetween from 'dayjs/plugin/isBetween';
import { isEmpty } from 'lodash-es';
import { CalendarHolidayList } from '#types/flight';
import { ref, computed, useCalendarConfig } from '#imports';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(isBetween);

interface ISharedCalendarProps {
  selectedDates?: string[];
  maxDate?: dayjs.ConfigType;
  minDate?: dayjs.ConfigType;
  month: number;
  year: number;
  holiday?: CalendarHolidayList;
  fares?: {
    [key: string]: number;
  };
  header?: boolean;
  showExtraDate?: boolean;
  isMobile?: boolean;
}

interface ISharedCalendarEmits {
  (e: 'select', value: string): void;
}

const props = withDefaults(defineProps<ISharedCalendarProps>(), {
  month: dayjs().month(),
  year: dayjs().year(),
  header: true,
  showExtraDate: false,
  isMobile: false,
});
const emit = defineEmits<ISharedCalendarEmits>();

const { formatFarePrice } = useCalendarConfig();

const hoveredDate = ref<string | undefined>();

const dateCtx = computed(() => dayjs().month(props.month).year(props.year));
const weekdays = computed(() => dayjs.weekdaysShort(true));
const months = computed(() => dayjs.months());
const currMonth = computed(() => dateCtx.value.month());
const currYear = computed(() => dateCtx.value.year());
const currDate = computed(() => dateCtx.value.get('date'));

const datesInMonth = computed(() => {
  // @see: https://github.com/iamkun/dayjs/issues/1272 - for the reason we're not using dayjs to do this.
  const daysInMonth = new Date(currYear.value, currMonth.value + 1, 0).getDate();
  const dates = [];

  for (let i = 1; i <= daysInMonth; i++) {
    dates.push(dayjs().year(currYear.value).month(currMonth.value).date(i).format('YYYY-MM-DD'));
  }

  return dates.map((date) => {
    const state = {
      dateString: date,
      day: getDay(date),
      inRange: false,
      selected: false,
      isPickStart: false,
      isPickEnd: false,
      isDefaultHoliday: false,
      isNationalHoliday: false,
      farePrice: 0,
      isLowestPrice: false,
      isDisabled: false,
    };
    const selectedDates = props.selectedDates;

    // if (hoveredDate.value && selectedDates) {
    //   selectedDates[0] = hoveredDate.value;
    // }

    if (selectedDates?.includes(date)) {
      state.selected = true;
    } else if ((selectedDates?.length || 0) > 1 && dayjs(date).isBetween(selectedDates![0], selectedDates![1])) {
      state.inRange = true;
    }

    if (selectedDates?.length === 2 && selectedDates[0] !== selectedDates[1]) {
      // check if dates is the first date selected
      if (dayjs(date).isSame(selectedDates![0])) state.isPickStart = true;
      // check if dates is the last date selected
      else if (dayjs(date).isSame(selectedDates![1])) state.isPickEnd = true;
    }

    // check if date is default holiday
    if (dayjs(date).get('day') === 0) state.isDefaultHoliday = true;

    // check if date is national holiday
    if (props.holiday?.[date]) state.isNationalHoliday = true;

    // get fare price
    if (props.fares) {
      state.farePrice = props.fares[date] ?? 0;

      const lowestFares = Math.min(...Object.values(props.fares));
      const currentFare = Number(props.fares[date]);

      state.isLowestPrice = lowestFares === currentFare;
    }

    // check disabled date
    if ((props.maxDate && dayjs(date).isAfter(props.maxDate)) || (props.minDate && dayjs(date).isBefore(props.minDate)))
      state.isDisabled = true;

    return state;
  });
});
const firstDatesOfMonth = computed(() => {
  let numOfDay = dayjs(dateCtx.value)
    .subtract(currDate.value - 1, 'days')
    .weekday();

  if (numOfDay < 0) numOfDay = 0;

  const lastDayOfPrevMonth = dayjs(dateCtx.value).subtract(1, 'month').endOf('month');

  return Array.from({ length: numOfDay }, (_, index) => {
    const date = dayjs()
      .year(lastDayOfPrevMonth.year())
      .month(lastDayOfPrevMonth.month())
      .date(lastDayOfPrevMonth.get('date') - index)
      .format('YYYY-MM-DD');
    const isDefaultHoliday = dayjs(date).get('day') === 0;
    const isNationalHoliday = !!props.holiday?.[date];

    return {
      date,
      isDefaultHoliday,
      isNationalHoliday,
    };
  }).reverse();
});
const lastDatesOfMonth = computed(() => {
  const numOfDay = 42 - datesInMonth.value.length - firstDatesOfMonth.value.length;

  const firstDayOfNextMonth = dayjs(dateCtx.value).add(1, 'month').startOf('month');

  return Array.from({ length: numOfDay }, (_, index) => {
    const date = dayjs()
      .year(firstDayOfNextMonth.year())
      .month(firstDayOfNextMonth.month())
      .date(index + 1)
      .format('YYYY-MM-DD');
    const isDefaultHoliday = dayjs(date).get('day') === 0;
    const isNationalHoliday = !!props.holiday?.[date];

    return {
      date,
      isDefaultHoliday,
      isNationalHoliday,
    };
  });
});
const holidayList = computed(() => {
  if (props.holiday)
    return Object.entries(props.holiday).map(([date, name]) => `${dayjs(date).format('DD MMM')} ${name}`);
});

function getDay(dateStr: string) {
  return dayjs(dateStr).date();
}

function onSelectDate(date: string) {
  emit('select', date);
}

function onMouseEnter(date: string) {
  hoveredDate.value = date;
}

// function onMouseLeave() {
//   hoveredDate.value = date
// }
</script>

<template>
  <div>
    <div
      v-if="header"
      class="font-bold text-center py-20"
    >
      {{ months[currMonth] }} {{ currYear }}
    </div>
    <div class="grid grid-cols-7 mb-10">
      <div
        v-for="(weekday, index) in weekdays"
        :key="weekday"
        class="text-center"
        :class="{
          'text-primary': index === (dayjs.localeData().firstDayOfWeek() === 1 ? 6 : 0),
          'text-small': isMobile,
        }"
      >
        {{ weekday }}
      </div>
    </div>
    <div class="grid grid-cols-7">
      <div
        v-for="date in firstDatesOfMonth"
        :key="date.date"
        class="flex justify-center items-center w-[42px] h-[42px] self-center justify-self-center opacity-50 cursor-not-allowed"
        :class="{ 'text-primary': date.isDefaultHoliday, '!opacity-0': !props.showExtraDate, 'text-small': isMobile }"
      >
        {{ getDay(date.date) }}
        <div
          v-if="date.isNationalHoliday"
          class="relative h-full"
        >
          <span class="w-3 h-3 rounded-full bg-secondary absolute top-1/3 left-3"></span>
        </div>
      </div>

      <div
        v-for="date in datesInMonth"
        :key="date.dateString"
        :aria-label="date.dateString"
        class="flex justify-center"
        data-testid="calendar-day-select"
        :class="{
          'bg-primary bg-opacity-10': date.inRange,
          'bg-gradient-to-r from-[#ffffff_50%] to-[#e31f251a_50%] !bg-opacity-10': date.isPickStart,
          'bg-gradient-to-l from-[#ffffff_50%] to-[#e31f251a_50%] !bg-opacity-10': date.isPickEnd,
        }"
      >
        <button
          v-if="!date.isDisabled"
          type="button"
          class="flex justify-center items-center w-[42px] h-[42px] cursor-pointer"
          :class="{
            'bg-primary text-white font-bold rounded-full': date.selected,
            'border-primary rounded-full hover:border-2': !date.selected,
            'text-primary': date.isDefaultHoliday,
          }"
          @click="onSelectDate(date.dateString)"
          @mouseenter="onMouseEnter(date.dateString)"
        >
          <div class="flex flex-col justify-center items-center relative">
            <div :class="{ 'text-small': isMobile }">
              {{ date.day }}
            </div>
            <div
              v-if="fares"
              :class="[
                date.selected ? 'text-whit font-bold' : date.isLowestPrice ? ' text-success ' : 'text-gray-dark',
                isMobile ? 'text-small' : 'text-xsmall',
              ]"
            >
              {{ date.farePrice ? formatFarePrice(date.farePrice) : '-' }}
            </div>
          </div>

          <div
            v-if="date.isNationalHoliday && !date.selected"
            class="relative h-full"
          >
            <span
              class="w-5 h-5 rounded-full bg-secondary absolute left-0"
              :class="[isMobile ? 'top-3' : 'top-5']"
            ></span>
          </div>
        </button>

        <div
          v-else
          :class="[
            'flex justify-center items-center w-[42px] h-[42px] cursor-not-allowed',
            { '-translate-y-[7px]': Boolean(fares) },
          ]"
        >
          <div class="flex flex-col justify-center items-center relative opacity-30">
            <div :class="{ 'text-small': isMobile }">
              {{ date.day }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-for="date in lastDatesOfMonth"
        :key="date.date"
        class="flex justify-center items-center w-[42px] h-[42px] self-center justify-self-center opacity-50"
        :class="{
          'text-primary': date.isDefaultHoliday,
          hidden: !props.showExtraDate && isMobile,
          '!opacity-0': !props.showExtraDate && !isMobile,
        }"
      >
        {{ getDay(date.date) }}
        <div
          v-if="date.isNationalHoliday"
          class="relative h-full"
        >
          <span class="w-3 h-3 rounded-full bg-secondary absolute top-1/3 left-3"></span>
        </div>
      </div>
    </div>
    <div
      v-if="!isEmpty(holidayList)"
      class="w-full px-15 flex flex-col gap-5 py-10"
    >
      <div
        v-for="(holiday, index) in holidayList"
        :key="index"
        class="text-xsmall text-gray-dark"
        data-testid="flightSearch-holidayCalendar-text"
      >
        {{ holiday }}
      </div>
    </div>
  </div>
</template>
